export function Impressum() {
  return (
    <main className="row justify-content-center">
      <div className="col-xl-6">
        <div style={{ padding: 8 }}>
          <h1>Impressum</h1>

          <p>Diensteanbieter gemäß § 5 TMG:</p>

          <p>
            Martin Ernst Bidlingmaier
            <br />
            Georgenstr. 42
            <br />
            80799 München
            <br />
            <a href="mailto:kontakt@werbungskostenrechner.de">
              kontakt@werbungskostenrechner.de
            </a>
          </p>
        </div>
      </div>
    </main>
  );
}
