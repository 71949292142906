export type ProtocolId = number;
export type CarId = string;

export type Car = {
  image: string;
  licensePlate: string | null;
  brand: string | null;
  color: string | null;
};

export type ProtocolSigns = {
  noStoppingSignLeft: number | null;
  noStoppingSignRight: number | null;
  noStoppingSignLeftRight: number | null;

  noParkingSignLeft: number | null;
  noParkingSignRight: number | null;
  noParkingSignLeftRight: number | null;
};

export type Protocol = {
  address: string | null;
  date: string | null;
  recorderName: string | null;
  signs: ProtocolSigns;
  // An object with strings as keys and numbers as values:
  carInfos: { [key: CarId]: Car };
};

export type ProtocolSummary = {
  address: string;
  date: string;
  recorderName: string;
  carCount: number;
};

export enum UserType {
  Admin = "Admin",
  Worker = "Worker",
}
